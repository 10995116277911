import type { FunctionComponent } from 'react'
import type { PrincipleListResult } from '../data/content/PrincipleListFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './PrincipleList.module.sass'

export type PrincipleListProps = {
	principleList: PrincipleListResult
}

export const PrincipleList: FunctionComponent<PrincipleListProps> = ({ principleList }) => {
	return (
		<Container size="wide">
			<div className={s.PrincipleList}>
				{principleList.items.map((principle) => (
					<div key={principle.id} className={s.PrincipleWrapper}>
						{principle.icon?.image && (
							<div className={s.Image}>
								<ContemberImage
									image={principle.icon?.image}
									layout="intrinsic"
									objectFit="cover"
								/>
							</div>
						)}
						<h4 className={s.Title}>{principle.title}</h4>
						<p className={s.Text}>{principle.text}</p>
					</div>
				))}
			</div>
		</Container>
	)
}
