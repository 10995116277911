import type { FunctionComponent } from 'react'
import { Container } from './Container'
import { Icon } from './Icon'
import s from './Line.module.sass'

export const Line: FunctionComponent = () => {
	return (
		<Container size="wide">
			<div className={s.Line}>
				<Icon name="line" />
			</div>
		</Container>
	)
}
