import Script from 'next/script'
import { FunctionComponent, useEffect } from 'react'

export const DarujmeWidget: FunctionComponent = () => {
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		if (typeof (window as any).Darujme !== 'undefined') {
			window.location.reload() // @TODO very hacky solution. Widget loads only after full page reload
		}
	}, [])
	return (
		<>
			<div data-darujme-widget-token="ribqpwm3gvbqnhr6">&nbsp;</div>
			<Script
				id="darujme-widget"
				dangerouslySetInnerHTML={{
					__html: `+function(w, d, s, u, a, b) {
w['DarujmeObject'] = u;
w[u] = w[u] || function () { (w[u].q = w[u].q || []).push(arguments) };
a = d.createElement(s); b = d.getElementsByTagName(s)[0];
a.async = 1; a.src = "https://www.darujme.cz/assets/scripts/widget.js";
b.parentNode.insertBefore(a, b);
}(window, document, 'script', 'Darujme');

Darujme(1, "ribqpwm3gvbqnhr6", 'render', "https://www.darujme.cz/widget?token=ribqpwm3gvbqnhr6", "270px");
`,
				}}
				strategy="lazyOnload"
			/>
		</>
	)
}
