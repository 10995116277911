import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent, ReactNode } from 'react'
import type { ContentReferenceType } from '../../generated/content'
import { usePageContext } from '../../pages/[[...page]]'
import type { ContentBlockResult } from '../data/content/ContentBlockFragment'
import type { ContentResult } from '../data/content/ContentFragment'
import { useContentRendererCopyPasteBugWorkaround } from '../utils/useContentRendererCopyPasteBugWorkaround'
import { AchievmentsCarousel } from './AchievmentsCarousel'
import { Banner } from './Banner'
import { Boxes } from './Boxes'
import { Button } from './Button'
import { CitationList } from './CitationList'
import { Container } from './Container'
import styles from './ContentRenderer.module.sass'
import { Downloads } from './Downloads'
import { Image } from './Image'
import { ImageLinkList } from './ImageLinkList'
import { Jumbotron } from './Jumbotron'
import { Line } from './Line'
import { Link } from './Link'
import { NewsAndStoriesBlock } from './NewsAndStoriesBlock'
import { PrincipleList } from './PrincipleList'
import { StaffList } from './StaffList'
import { Steps } from './Steps'
import { Widget } from './Widget'
import { Wysiwyg } from './Wysiwyg'
import { YoutubeVideo } from './YoutubeVideo'

export interface ContentRendererProps {
	content: ContentResult
	sideContent?: React.ReactNode
}

type ContentBlockReference = ContentBlockResult['references'][number]
type Block = ReferenceRendererProps<ContentBlockReference>

const standaloneTypes = ['reference']
const nestedTypes = ['listItem', 'anchor']

const referenceRenderers: {
	[referenceType in ContentReferenceType]?: (block: Block) => ReactNode
} = {
	achievementList({ reference }) {
		return reference.textList && <AchievmentsCarousel achievmentsList={reference.textList} />
	},
	image({ reference }) {
		return reference.image && <Image image={reference.image} alt="" />
	},
	banner({ reference }) {
		return (
			reference.primaryText &&
			reference.secondaryText &&
			reference.image && (
				<Banner
					title={reference.primaryText}
					text={reference.secondaryText}
					image={reference.image}
				/>
			)
		)
	},
	citationList({ reference }) {
		return reference.citationList && <CitationList citationList={reference.citationList} />
	},
	downloads({ reference }) {
		return (
			reference.primaryText &&
			reference.fileList && (
				<Downloads title={reference.primaryText} fileList={reference.fileList} />
			)
		)
	},
	imageList({ reference }) {
		return reference.imageLinkList && <ImageLinkList imageList={reference.imageLinkList} />
	},
	storiesBlock({ reference }) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const page = usePageContext()

		const stories =
			reference.storiesList?.items && reference.storiesList.items.length > 0
				? reference.storiesList.items.filter((story) => story !== undefined)
				: page.dataFromContember.listStories.filter((story) => story !== undefined)
		return (
			<NewsAndStoriesBlock
				title={reference.primaryText ?? ''}
				stories={stories}
				link={reference.link}
			/>
		)
	},
	newsBlock({ reference }) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const page = usePageContext()

		return (
			reference.primaryText && (
				<NewsAndStoriesBlock
					title={reference.primaryText}
					news={page.dataFromContember.listNews}
					link={reference.link}
				/>
			)
		)
	},
	jumbotron({ reference }) {
		return (
			reference.primaryText &&
			reference.view && (
				<Jumbotron
					title={reference.primaryText}
					subtitle={reference.secondaryText}
					list={reference.textList}
					otherText={reference.tertiaryText}
					link={reference.link}
					view={reference.view}
				/>
			)
		)
	},
	steps({ reference }) {
		return (
			reference.textList && <Steps textList={reference.textList} numbered={reference.numbered} />
		)
	},
	youtubeVideo({ reference }) {
		return reference.youtubeVideo && <YoutubeVideo videoId={reference.youtubeVideo.videoId} />
	},
	staff({ reference }) {
		return reference.staffList && <StaffList staffList={reference.staffList} />
	},
	boxes({ reference }) {
		return reference.textList && <Boxes textList={reference.textList} />
	},
	principles({ reference }) {
		return reference.principleList && <PrincipleList principleList={reference.principleList} />
	},
	button({ reference }) {
		return (
			reference.link &&
			reference.aligning && (
				<Container size="wide">
					<Button link={reference.link} aligning={reference.aligning} buttonSize />
				</Container>
			)
		)
	},
	line() {
		return <Line />
	},
	widget({ reference }) {
		return reference.primaryText && <Widget token={reference.primaryText} />
	},
}

export type InternalAnchorElement = {
	type: 'internalAnchor'
	suchThat: { nevim?: string }
}

type CustomElements = InternalAnchorElement

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({
	content,
	sideContent,
}) => {
	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)

	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<RichTextRenderer
					blocks={blocks}
					sourceField="json"
					referenceRenderers={referenceRenderers}
					renderElement={(element) => {
						const el = element.element as typeof element['element'] | CustomElements
						const reference = element.reference as ContentBlockReference
						const { type } = el
						switch (el.type) {
							case 'internalAnchor':
								return reference.link ? <Link link={reference.link}>{element.children}</Link> : null
						}
						if (nestedTypes.includes(type)) {
							return element.fallback
						}
						if (standaloneTypes.includes(type)) {
							return (
								<div>
									{type !== 'reference' ||
									!element.referenceType ||
									element.referenceType in referenceRenderers ? (
										element.fallback
									) : (
										<div className={styles.notImplemented}>
											<div className={styles.notImplemented_name}>{element.referenceType}</div>
											is not yet implemented
										</div>
									)}
								</div>
							)
						}
						return <Wysiwyg>{element.fallback}</Wysiwyg>
					}}
				/>
			</div>
			<aside className={styles.aside}>{sideContent}</aside>
		</div>
	)
}
