import type { NewsResult } from '../data/content/NewsFragment'
import type { StoriesResult } from '../data/content/StoriesFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContentRenderer } from './ContentRenderer'
import s from './NewsAndStory.module.sass'

export type NewsAndStoryProps = {
	news?: NewsResult
	stories?: StoriesResult
}

export function NewsAndStory(props: NewsAndStoryProps) {
	return (
		<div className={s.newsAndStory}>
			<Container size="wide">
				<div className={s.header}>
					{props.news?.publishDate && (
						<time>{new Date(props.news.publishDate).toLocaleDateString()}</time>
					)}
					<h2 className={s.title}>
						{(props.news && props.news.title) || (props.stories && props.stories.title)}
					</h2>
					{(props.news?.coverImage && (
						<div className={s.imageBox}>
							<ContemberImage image={props.news.coverImage} />
						</div>
					)) ||
						(props.stories?.coverImage && (
							<div className={s.imageBox}>
								<ContemberImage image={props.stories.coverImage} />
							</div>
						))}
					{(props.news?.perex && <p className={s.perex}>{props.news.perex}</p>) ||
						(props.stories?.perex && <p className={s.perex}>{props.stories.perex}</p>)}
				</div>
			</Container>
			<div className={s.content}>
				{(props.news?.content && <ContentRenderer content={props.news.content} />) ||
					(props.stories?.content && <ContentRenderer content={props.stories.content} />)}
			</div>
		</div>
	)
}
