import { RichTextRenderer } from '@contember/react-client'
import type { GenericPageResult } from '../data/content/GenericPageFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContentRenderer } from './ContentRenderer'
import { DarujmeWidget } from './DarujmeWidget'
import s from './GenericPage.module.sass'

export type GenericProps = {
	genericPage: GenericPageResult
}

export function GenericPage(props: GenericProps) {
	return (
		<>
			<div className={s.GenericPage}>
				<Container size="wide">
					<div className={s.Cover}>
						{props.genericPage.leadParagraph && (
							<div className={s.LeadParagraph}>
								<RichTextRenderer source={props.genericPage.leadParagraph} />
							</div>
						)}
						{props.genericPage.image && (
							<div
								className={s.Image}
								style={{ maxWidth: (props.genericPage.image.width ?? 0) / 2 }}>
								<ContemberImage image={props.genericPage.image} layout="intrinsic" />
							</div>
						)}
					</div>
				</Container>
				{props.genericPage.content && (
					<ContentRenderer
						content={props.genericPage.content}
						sideContent={props.genericPage.darujmeWidget && <DarujmeWidget />}
					/>
				)}
			</div>
		</>
	)
}
