import type { FunctionComponent } from 'react'
import type { StaffListResult } from '../data/content/StaffListFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './StaffList.module.sass'

export type StaffListProps = {
	staffList: StaffListResult
}

export const StaffList: FunctionComponent<StaffListProps> = ({ staffList }) => {
	return (
		<Container size="normal">
			<div className={s.StaffList}>
				{staffList.items.map((staff) => (
					<div key={staff.id} className={s.StaffWrapper}>
						<div className={s.Staff}>
							{staff.staff?.image && (
								<ContemberImage image={staff.staff?.image} layout="intrinsic" />
							)}
							<p className={s.Name}>{staff.staff?.name}</p>
							<p className={s.Position}>{staff.staff?.position}</p>
							<p className={s.Info}>{staff.staff?.info}</p>
						</div>
					</div>
				))}
			</div>
		</Container>
	)
}
