import clsx from 'clsx'
import NextLink from 'next/link'
import React from 'react'
import { LinkType } from '../../generated/content'
import { useActivePage } from '../contexts/ActiveUrlContext'
import type { LinkResult } from '../data/content/LinkFragment'
import { ContemberLink } from './ContemberLink'

export type LinkProps = {
	className?: string
	activeClassName?: string
	children?: React.ReactNode
	onClick?: React.MouseEventHandler<HTMLAnchorElement>
} & ({ link: LinkResult | undefined } | { href: string })

export function Link(props: LinkProps) {
	const activeUrl = useActivePage()

	// const { type, internalLink, externalLink, anchor } = props.link ?? {}
	// const { href, ...attrs } = React.useMemo(() => {
	// 	const attrs: Partial<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>> = {}

	// 	switch (type) {
	// 		case LinkEnum.internal:
	// 			return { ...attrs, href: internalLink?.url ?? '#' }
	// 		case LinkEnum.anchor:
	// 			return { ...attrs, href: `${internalLink?.url}#${anchor}` }
	// 		case LinkEnum.external:
	// 		default:
	// 			return { ...attrs, href: externalLink ?? '#' }
	// 	}
	// }, [anchor, externalLink, internalLink?.url, type])

	if ('href' in props) {
		const isActive = activeUrl === props.href
		return (
			<NextLink href={props.href}>
				{/* eslint-disable-next-line */}
				<a
					className={clsx(props.className, isActive && props.activeClassName)}
					onClick={props.onClick}>
					{props.children}
				</a>
			</NextLink>
		)
	} else if ('link' in props) {
		const link = props.link
		const url =
			link?.type === LinkType.internal
				? link.internalLink?.url
				: link?.type === LinkType.external
				? link.externalLink
				: ''
		const isActive = activeUrl === url

		return (
			<ContemberLink
				link={props.link}
				className={clsx(props.className, isActive && props.activeClassName)}>
				{props.children}
			</ContemberLink>
		)
	} else {
		return null
	}
}
