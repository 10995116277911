import { RichTextRenderer } from '@contember/react-client'
import type { HomePageResult } from '../data/content/HomePageFragment'
import type { ProgramResult } from '../data/content/ProgramFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContentRenderer } from './ContentRenderer'
import s from './HomePage.module.sass'
import { ProgramTile } from './ProgramTile'

export type HomeProps = {
	homePage: HomePageResult
	programs: ProgramResult[]
}

export function HomePage(props: HomeProps) {
	return (
		<>
			<div className={s.HomePage}>
				<Container size="wide">
					<div className={s.Cover}>
						{props.homePage.leadParagraph && (
							<div className={s.LeadParagraph}>
								<RichTextRenderer source={props.homePage.leadParagraph} />
							</div>
						)}
						{props.homePage.logo && (
							<div className={s.Image} style={{ maxWidth: (props.homePage.logo.width ?? 0) / 2 }}>
								<ContemberImage image={props.homePage.logo} layout="intrinsic" />
							</div>
						)}
					</div>

					<div className={s.Programs}>
						{props.programs?.map((program) => (
							<ProgramTile key={program.id} program={program} />
						))}
					</div>
				</Container>
				{props.homePage.content && <ContentRenderer content={props.homePage.content} />}
			</div>
		</>
	)
}
