import clsx from 'clsx'
import { useSwiperReactive } from '../hooks/useSwiperReactive'
// import type { CarouselCardsProps } from './CarouselCards'
import s from './AchievmentsCarousel.module.sass'
import { Icon } from './Icon'

export const CarouselNavigationSwiper = () => {
	const swiper = useSwiperReactive()

	const isPreviousButtonVisible = !swiper.isBeginning
	const isNextButtonVisible = !swiper.isEnd
	return (
		<div className={clsx(s.Wrapper)}>
			<button
				type="button"
				className={clsx(s.previousButton, !isPreviousButtonVisible && s.is_low)}
				aria-label="previous"
				onClick={() => {
					swiper.slidePrev()
				}}>
				<Icon name="button" />
			</button>

			<button
				type="button"
				aria-label="next"
				className={clsx(s.nextButton, !isNextButtonVisible && s.is_low)}
				onClick={() => swiper.slideNext()}>
				<Icon name="button" />
			</button>
		</div>
	)
}
