import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { FileListResult } from '../data/content/FileListFragment'
import { Button } from './Button'
import { Container } from './Container'
import s from './Downloads.module.sass'
import { Icon } from './Icon'

export type DownloadsProps = {
	title: string
	fileList: FileListResult
}

export const Downloads: FunctionComponent<DownloadsProps> = ({ title, fileList }) => {
	return (
		<Container size="wide">
			<div className={s.Downloads} data-files-count={fileList.items.length}>
				<h3 className={s.Title}>{title}</h3>
				<div className={s.Files} data-files-count={fileList.items.length}>
					{fileList.items.map(
						(file) =>
							file.url && (
								<Link key={file.id} href={file.url}>
									<a className={s.File} target="_blank" rel="noopener">
										<div className={s.FileImage}>
											<Icon name="file" />
										</div>
										<div className={s.FileNameBox}>
											<p className={s.FileName}>{file.name}</p>
											<div className={s.Button}>
												<Button text="Stáhnout" />
											</div>
										</div>
									</a>
								</Link>
							)
					)}
				</div>
			</div>
		</Container>
	)
}
