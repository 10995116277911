import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { View } from '../../generated/content'
import type { LinkResult } from '../data/content/LinkFragment'
import type { TextListResult } from '../data/content/TextListFragment'
import { Button } from './Button'
import { Container } from './Container'
import { Icon } from './Icon'
import s from './Jumbotron.module.sass'
import JumbotronImage from './JumbotronImage.png'

export type JumbotronProps = {
	title: string
	subtitle?: string
	list?: TextListResult
	otherText?: string
	link?: LinkResult
	view: View
}

export const Jumbotron: FunctionComponent<JumbotronProps> = ({
	title,
	subtitle,
	list,
	otherText,
	link,
	view,
}) => {
	return (
		<div className={s.Jumbotron}>
			<Container size="wide">
				{view === View.noLine ? (
					<div className={s.Container}>
						<div className={s.TextBox}>
							<h3 className={s.Title}>{title}</h3>
							<div className={s.Box}>
								<div className={s.ContentBox}>
									<p className={clsx(s.Subtitle, s.SubtitleWide)}>{subtitle}</p>
									{list?.items && (
										<ol className={s.Subtitle}>
											{list?.items.map((item) => (
												<li key={item.id}>
													{item.title && <RichTextRenderer source={item.title} />}
												</li>
											))}
										</ol>
									)}
									{otherText && <p className={s.Subtitle}>{otherText}</p>}
									<div className={s.Button}>
										<Button link={link} />
									</div>
								</div>
								<div className={s.ImageBox} style={{ maxWidth: (JumbotronImage.width ?? 0) / 2 }}>
									<Image src={JumbotronImage} alt="" />
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className={s.Container}>
						<div>
							<h3 className={s.Title}>{title}</h3>
							<p className={s.Subtitle}>{subtitle}</p>
							{list?.items && (
								<ol className={s.Subtitle}>
									{list?.items.map((item) => (
										<li key={item.id}>{item.title && <RichTextRenderer source={item.title} />}</li>
									))}
								</ol>
							)}
							{otherText && <p className={s.Subtitle}>{otherText}</p>}
							<div className={s.Line}>
								<Icon name="line" />
							</div>
						</div>
						<div className={s.ButtonWrapper}>
							<div className={s.Heart}>
								<Icon name="heart" />
							</div>
							<div className={s.Button}>
								<Button link={link} />
							</div>
						</div>
					</div>
				)}
			</Container>
		</div>
	)
}
