import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { TextListResult } from '../data/content/TextListFragment'
import s from './AchievmentsCarousel.module.sass'
import { CarouselNavigationSwiper } from './CarouselNavigationSwiper'
import { Container } from './Container'

export type AchievmentsCarouselProps = {
	achievmentsList: TextListResult
}

export const AchievmentsCarousel: FunctionComponent<AchievmentsCarouselProps> = ({
	achievmentsList,
}) => {
	// const swiper = useSwiperReactive()

	// const isPreviousButtonVisible = !swiper.isBeginning
	// const isNextButtonVisible = !swiper.isEnd

	return (
		<Container size="wide">
			<div className={s.wrapperOut}>
				<div className={s.wrapper}>
					<Swiper
						breakpoints={{
							300: {
								slidesPerView: 1,
								spaceBetween: 32,
							},
							900: {
								slidesPerView: 1,
								spaceBetween: 16,
							},
							1200: {
								slidesPerView: 3,
								spaceBetween: 16,
							},
						}}
						centeredSlides={true}
						roundLengths={true}
						initialSlide={0}
						slidesPerGroup={1}
						centeredSlidesBounds={true}
						style={{
							zIndex: 0,
							marginInline: '10%',
							marginBlock: '2rem',
							overflow: 'hidden',
						}}>
						{achievmentsList.items.map((achievment, index) => (
							<SwiperSlide key={achievment.id}>
								<div key={index} className={s.achievmentWrapper}>
									<div className={s.achievmesntBox}>
										{achievment.title && (
											<div className={s.title}>
												<RichTextRenderer source={achievment.title} />
											</div>
										)}
										{achievment.text && (
											<div className={s.text}>
												<RichTextRenderer source={achievment.text} />
											</div>
										)}
									</div>
								</div>
							</SwiperSlide>
						))}
						<CarouselNavigationSwiper />
					</Swiper>
				</div>
			</div>
		</Container>
	)
}
