import { RichTextRenderer } from '@contember/react-client'
import type { NewsResult } from '../data/content/NewsFragment'
import type { NewsPageResult } from '../data/content/NewsPageFragment'
import type { StoriesResult } from '../data/content/StoriesFragment'
import { Container } from './Container'
import s from './NewsAndStoriesPage.module.sass'
import { NewsAndStoriesTile } from './NewsAndStoriesTile'

export type NewsAndStoriesProps = {
	newsPage: NewsPageResult
	news?: NewsResult[]
	stories?: StoriesResult[]
}

export function NewsAndStoriesPage(props: NewsAndStoriesProps) {
	return (
		<>
			<div className={s.newsPage}>
				<Container size="wide">
					<div className={s.cover}>
						{props.newsPage.leadParagraph && (
							<div className={s.leadParagraph}>
								<RichTextRenderer source={props.newsPage.leadParagraph} />
							</div>
						)}
					</div>

					<div className={s.news}>
						{props.news?.map((news) => (
							<NewsAndStoriesTile key={news.id} news={news} />
						))}
						{props.stories?.map((story) => (
							<NewsAndStoriesTile key={story.id} news={story} />
						))}
					</div>
				</Container>
			</div>
		</>
	)
}
