import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { ImageLinkListResult } from '../data/content/ImageLinkListFragment'
import { Container } from './Container'
import s from './ImageLinkList.module.sass'

export type ImageLinkListProps = {
	imageList: ImageLinkListResult
}

export const ImageLinkList: FunctionComponent<ImageLinkListProps> = (imageList) => {
	return (
		<div className={s.imageLinkListWrapper}>
			<Container size="wide">
				<h3 className={s.imageLinkListTitle}>{imageList.imageList.title}</h3>
				<div className={s.imageLinkListBox}>
					{imageList.imageList.items.map(
						(image, index) =>
							image.image && (
								<div key={image.id} className={clsx(s.imageLinkListItem, index === 0 && s.first)}>
									<img src={image.image.url} alt={image.image.alt} className={s.image} />
								</div>
							)
					)}
				</div>
			</Container>
		</div>
	)
}
