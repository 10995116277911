import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import type { TextListResult } from '../data/content/TextListFragment'
import s from './Boxes.module.sass'
import { Button } from './Button'
import { Container } from './Container'

export type BoxesProps = {
	textList: TextListResult
}

export const Boxes: FunctionComponent<BoxesProps> = ({ textList }) => {
	return (
		<Container size="wide">
			<div className={s.Boxes}>
				{textList.items.map((item) => (
					<div key={item.id} className={s.BoxWrapper}>
						<div key={item.id} className={s.Box}>
							<div>
								{item.title && (
									<div className={s.Title}>
										<RichTextRenderer source={item.title} />
									</div>
								)}
								{item.text && (
									<div className={s.Text}>
										<RichTextRenderer source={item.text} />
									</div>
								)}
							</div>
							<div className={s.Link}>
								<Button link={item.link} />
							</div>
						</div>
					</div>
				))}
			</div>
		</Container>
	)
}
