import type { FunctionComponent } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './Image.module.sass'

export type ImageProps = {
	image: ImageResult
	alt?: string
}

export const Image: FunctionComponent<ImageProps> = ({ image }) => {
	return (
		<Container size="wide">
			<div className={s.ImageWrapper} style={{ maxWidth: (image.width ?? 0) / 2 }}>
				<ContemberImage image={image} layout="intrinsic" objectFit="cover" />
			</div>
		</Container>
	)
}
