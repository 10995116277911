import type { FunctionComponent } from 'react'
import { Container } from './Container'
import s from './Widget.module.sass'

export type WidgetProps = {
	token: string
}

export const Widget: FunctionComponent<WidgetProps> = (props) => {
	return (
		<Container size="wide">
			<div className={s.widgetBox}>
				{props.token && (
					<iframe
						title="Darujme.cz widget"
						src={`https://www.darujme.cz/widget?token=${props.token}`}
						scrolling="no"
						name={`widget-${props.token}`}
						className={s.widget}></iframe>
				)}
			</div>
		</Container>
	)
}
