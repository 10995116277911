import type { NewsResult } from '../data/content/NewsFragment'
import type { StoriesResult } from '../data/content/StoriesFragment'
import { Link } from './Link'
import s from './NewsAndStoriesTile.module.sass'

export type NewsAndStoriesTileProps = {
	news?: NewsResult
	stories?: StoriesResult
}

export function NewsAndStoriesTile(props: NewsAndStoriesTileProps) {
	return (
		<>
			{props.news?.link?.url && (
				<div className={s.newsAndStoriesTile}>
					<NewsTileIn {...props} />
				</div>
			)}
			{props.stories?.link?.url && (
				<div className={s.newsAndStoriesTile}>
					<NewsTileIn {...props} />
				</div>
			)}
		</>
	)
}

export function NewsTileIn(props: NewsAndStoriesTileProps) {
	return (
		<>
			{props.news && (
				<>
					{props.news.coverImage &&
						(props.news.link ? (
							<Link href={props.news.link.url}>
								<img
									src={props.news.coverImage.url}
									alt={props.news.coverImage.alt}
									className={s.image}
								/>
							</Link>
						) : (
							<img
								src={props.news.coverImage.url}
								alt={props.news.coverImage.alt}
								className={s.image}
							/>
						))}
					{props.news.publishDate && (
						<p>{new Date(props.news.publishDate).toLocaleDateString('cs-CS')}</p>
					)}
					{props.news.link ? (
						<Link href={props.news.link.url}>
							<h3 className={s.heading}>{props.news.title}</h3>
						</Link>
					) : (
						<h3 className={s.heading}>{props.news.title}</h3>
					)}
					<p className={s.perex}>{props.news.perex}</p>
				</>
			)}
			{props.stories && (
				<>
					<div className={s.image}>
						{props.stories.coverImage &&
							(props.stories.link ? (
								<Link href={props.stories.link.url}>
									<img
										src={props.stories.coverImage.url}
										alt={props.stories.coverImage.alt}
										className={s.image}
									/>
								</Link>
							) : (
								<img
									src={props.stories.coverImage.url}
									alt={props.stories.coverImage.alt}
									className={s.image}
								/>
							))}
					</div>
					{props.stories.link ? (
						<Link href={props.stories.link.url}>
							<h3 className={s.heading}>{props.stories.title}</h3>
						</Link>
					) : (
						<h3 className={s.heading}>{props.stories.title}</h3>
					)}
					<p className={s.perex}>{props.stories.perex}</p>
				</>
			)}
		</>
	)
}
