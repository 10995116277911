import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { TextListResult } from '../data/content/TextListFragment'
import { Container } from './Container'
import s from './Steps.module.sass'

export type StepsProps = {
	textList: TextListResult
	numbered?: boolean
}

export const Steps: FunctionComponent<StepsProps> = ({ textList, numbered }) => {
	return (
		<Container size="wide">
			<div className={s.Steps}>
				{textList.items.map((item, i) => (
					<div
						key={item.id}
						className={clsx(
							textList.items.length < 3 && s.TwoSteps,
							s.StepWrapper,
							numbered && s.Numbered
						)}>
						<div key={item.id} className={clsx(s.Step, numbered && s.StepNumbered)}>
							{item.title && (
								<div className={s.Title}>
									{numbered && <div className={s.Number}>{i + 1}</div>}
									<RichTextRenderer source={item.title} />
								</div>
							)}
							{item.text && (
								<div className={s.Text}>
									<RichTextRenderer source={item.text} />
								</div>
							)}
						</div>
					</div>
				))}
			</div>
		</Container>
	)
}
