import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { CitationListResult } from '../data/content/CitationListFragment'
import s from './CitationList.module.sass'
import { Container } from './Container'
import { Icon } from './Icon'
import { useCarousel } from './useCarousel'

export type CitationListProps = {
	citationList: CitationListResult
}

export const CitationList: FunctionComponent<CitationListProps> = ({ citationList }) => {
	const { cardsElement, onSlideChange, showPreviousButton, showNextButton, scroll } = useCarousel()
	return (
		<Container size="normal">
			<div className={s.carousel}>
				<button
					className={clsx(s.nextButton, !showPreviousButton && s.hidden)}
					onClick={() => scroll(-1)}>
					<Icon name="button" />
				</button>
				<div className={s.citationList} onScroll={() => onSlideChange()} ref={cardsElement}>
					{citationList.items.map((citation, index) => (
						<div key={index} className={s.citationWrapper}>
							<div className={s.citationBox}>
								<h4 className={s.title}>{citation.title}</h4>
								{citation.image && (
									<div className={s.imageBox}>
										<img src={citation.image.url} alt={citation.image.id} className={s.image} />
									</div>
								)}
								<p className={s.text}>{citation.text}</p>
							</div>
						</div>
					))}
				</div>
				<button
					className={clsx(s.previousButton, !showNextButton && s.hidden)}
					onClick={() => scroll(1)}>
					<Icon name="button" />
				</button>
			</div>
		</Container>
	)
}
