import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { Aligning } from '../../generated/content'
import type { LinkResult } from '../data/content/LinkFragment'
import s from './Button.module.sass'
import { ContemberLink } from './ContemberLink'
import { Icon } from './Icon'

export type ButtonProps = {
	text?: string
	link?: LinkResult
	aligning?: Aligning
	buttonSize?: boolean
}

export const Button: FunctionComponent<ButtonProps> = ({ text, link, aligning, buttonSize }) => {
	return (
		<div
			className={clsx(
				s.Button,
				aligning === Aligning.center && s.Center,
				aligning === Aligning.right && s.Right,
				buttonSize && s.ButtonSize
			)}>
			{text && (
				<>
					<p className={s.Text}>{text}</p>
					<div className={s.Image}>
						<Icon name="button" />
					</div>
				</>
			)}
			{link && (
				<ContemberLink link={link}>
					<div className={s.Button}>
						<p className={s.Text}>{link?.title}</p>
						<div className={s.Image}>
							<Icon name="button" />
						</div>
					</div>
				</ContemberLink>
			)}
		</div>
	)
}
