import type { FunctionComponent } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import s from './Banner.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'

export type BannerProps = {
	title: string
	text: string
	image: ImageResult
}

export const Banner: FunctionComponent<BannerProps> = ({ title, text, image }) => {
	return (
		<Container size="wide">
			<div className={s.Wrapper}>
				<div className={s.Image}>
					<ContemberImage image={image} layout="fill" objectFit="cover" />
				</div>
				<div className={s.Banner}>
					<h3 className={s.Title}>{title}</h3>
					<div className={s.Box}>
						<p className={s.Text}>{text}</p>
						<div className={s.Bridge}>
							<Icon name="bridge" />
						</div>
					</div>
				</div>
			</div>
		</Container>
	)
}
