import { RichTextRenderer } from '@contember/react-client'
import type { ProgramResult } from '../data/content/ProgramFragment'
import type { ProgramPageResult } from '../data/content/ProgramPageFragment'
import { Container } from './Container'
import s from './HomePage.module.sass'
import { ProgramTile } from './ProgramTile'

export type ProgramProps = {
	programPage: ProgramPageResult
	programs: ProgramResult[]
}

export function ProgramPage(props: ProgramProps) {
	return (
		<>
			<div className={s.ProgramPage}>
				<Container size="wide">
					<div className={s.Cover}>
						{props.programPage.leadParagraph && (
							<div className={s.LeadParagraph}>
								<RichTextRenderer source={props.programPage.leadParagraph} />
							</div>
						)}
					</div>

					<div className={s.Programs}>
						{props.programs?.map((program) => (
							<ProgramTile key={program.id} program={program} />
						))}
					</div>
				</Container>
			</div>
		</>
	)
}
