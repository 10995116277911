import type { FunctionComponent } from 'react'
import type { LinkResult } from '../data/content/LinkFragment'
import type { NewsResult } from '../data/content/NewsFragment'
import type { StoriesResult } from '../data/content/StoriesFragment'
import { Button } from './Button'
import { Container } from './Container'
import s from './NewsAndStoriesBlock.module.sass'
import { NewsAndStoriesTile } from './NewsAndStoriesTile'

export type NewsAndStoriesBlockProps = {
	title: string
	news?: NewsResult[]
	stories?: StoriesResult[]
	link?: LinkResult
}

export const NewsAndStoriesBlock: FunctionComponent<NewsAndStoriesBlockProps> = ({
	title,
	news,
	stories,
	link,
}) => {
	return (
		<Container size="wide">
			<h2 className={s.title}>{title}</h2>
			<div className={s.itemsBox}>
				{news?.slice(0, 3).map((news) => (
					<NewsAndStoriesTile key={news.id} news={news} />
				))}
				{stories?.slice(0, 3).map((story) => (
					<NewsAndStoriesTile key={story.id} stories={story} />
				))}
			</div>
			{link?.title && (
				<div className={s.button}>
					<Button link={link} />
				</div>
			)}
		</Container>
	)
}
