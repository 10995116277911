import clsx from 'clsx'
import type { ProgramResult } from '../data/content/ProgramFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContentRenderer } from './ContentRenderer'
import s from './Program.module.sass'

export type ProgramProps = {
	program: ProgramResult
}

export function Program(props: ProgramProps) {
	return (
		<div className={s.Program}>
			<Container size="wide">
				<div className={s.Images}>
					{props.program.ilustration && (
						<div className={clsx(s.Image, s.Ilustration)}>
							<div style={{ maxWidth: (props.program.ilustration.width ?? 0) / 2 }}>
								<ContemberImage image={props.program.ilustration} layout="intrinsic" />
							</div>
						</div>
					)}
					{props.program.image && (
						<div className={clsx(s.Image, s.Photo)}>
							<ContemberImage image={props.program.image} layout="intrinsic" />
						</div>
					)}
				</div>
				<h1 className={s.Title}>{props.program.title}</h1>
				<h3 className={s.LeadParagraph}>{props.program.leadParagraph}</h3>
			</Container>
			<div className={s.Content}>
				{props.program.content && <ContentRenderer content={props.program.content} />}
			</div>
		</div>
	)
}
