import { Container } from './Container'
import s from './YoutubeVideo.module.sass'

export type YoutubeVideoProps = {
	videoId: string
}

export function YoutubeVideo({ videoId }: YoutubeVideoProps) {
	return (
		<Container size="wide">
			<div className={s.YoutubeVideo}>
				<iframe
					width="1600"
					height="900"
					src={`https://www.youtube.com/embed/${videoId}`}
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					title="YouTube Video"></iframe>
			</div>
		</Container>
	)
}
