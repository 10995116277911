import type { ProgramResult } from '../data/content/ProgramFragment'
import { Button } from './Button'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'
import s from './ProgramTile.module.sass'

export type ProgramTileProps = {
	program: ProgramResult
}

export function ProgramTile(props: ProgramTileProps) {
	return (
		<div className={s.ProgramTile}>
			<ProgramTileIn {...props} />
		</div>
	)
}

export function ProgramTileIn(props: ProgramTileProps) {
	return (
		<>
			{props.program.image &&
				(props.program.link ? (
					<Link href={props.program.link.url}>
						<ContemberImage image={props.program.image} />
					</Link>
				) : (
					<ContemberImage image={props.program.image} />
				))}
			{props.program.link ? (
				<Link href={props.program.link.url}>
					<h3 className={s.Heading}>{props.program.name}</h3>
				</Link>
			) : (
				<h3 className={s.Heading}>{props.program.name}</h3>
			)}
			<p>{props.program.leadParagraph}</p>

			<ul className={s.TextList}>
				{props.program.bulletPoint?.items.map((item) => (
					<li key={item.id} className={s.Text}>
						{item.text}
					</li>
				))}
			</ul>
			{props.program.showDetailLink && props.program.link && (
				<Link href={props.program.link.url} className={s.LinkText}>
					<Button text="Více o programu" />
				</Link>
			)}
		</>
	)
}
