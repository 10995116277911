import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import { createContext, useContext } from 'react'
import { Footer } from '../app/components/Footer'
import { GenericPage } from '../app/components/GenericPage'
import { Header } from '../app/components/Header'
import { HomePage } from '../app/components/HomePage'
import { NewsAndStoriesPage } from '../app/components/NewsAndStoriesPage'
import { NewsAndStory } from '../app/components/NewsAndStory'
import { Program } from '../app/components/Program'
import { ProgramPage } from '../app/components/ProgramPage'
import { Seo } from '../app/components/Seo'
import { combineUrl } from '../app/data/combineUrl'
import { FooterFragment } from '../app/data/content/FooterFragment'
import { GenericPageFragment } from '../app/data/content/GenericPageFragment'
import { HeaderFragment } from '../app/data/content/HeaderFragment'
import { HomePageFragment } from '../app/data/content/HomePageFragment'
import { NewsFragment } from '../app/data/content/NewsFragment'
import { NewsPageFragment } from '../app/data/content/NewsPageFragment'
import { ProgramFragment } from '../app/data/content/ProgramFragment'
import { ProgramPageFragment } from '../app/data/content/ProgramPageFragment'
import { StoriesFragment } from '../app/data/content/StoriesFragment'
import { StoriesPageFragment } from '../app/data/content/StoriesPageFragment'
import { One, OrderDirection, Thunder } from '../generated/content'
import { RespondWithNotFound, serverPropsHandler } from '../libs/next/api/handlers'
import { getZeusConnection } from '../libs/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage(props: CommonPageProps) {
	const header = props.dataFromContember.getHeader
	const programList = props.dataFromContember.listProgram
	const newsList = props.dataFromContember.listNews
	const storiesList = props.dataFromContember.listStories
	const { homePage, programPage, program, genericPage, newsPage, storiesPage, news, story } =
		props.dataFromContember.getLinkable ?? {}
	const footer = props.dataFromContember.getFooter

	return (
		<>
			<PageContext.Provider value={props}>
				<Seo seo={homePage?.seo ?? programPage?.seo ?? program?.seo ?? genericPage?.seo} />
				<Head>
					<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
					<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
					<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
					<link rel="manifest" href="/site.webmanifest" />
					<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#eb1c23" />
					<meta name="msapplication-TileColor" content="#ffc40d" />
					<meta name="theme-color" content="#ffffff" />
				</Head>

				{header && <Header header={header} />}
				{genericPage && <GenericPage genericPage={genericPage} />}
				{homePage && <HomePage homePage={homePage} programs={programList} />}
				{programPage && <ProgramPage programPage={programPage} programs={programList} />}
				{storiesPage && <NewsAndStoriesPage newsPage={storiesPage} news={storiesList} />}
				{newsPage && <NewsAndStoriesPage newsPage={newsPage} news={newsList} />}
				{program && <Program program={program} />}
				{news && <NewsAndStory news={news} />}
				{story && <NewsAndStory stories={story} />}

				{footer && <Footer footer={footer} />}
				{/* <section>
				<h1>
					Props from <code>getServerSideProps</code>
				</h1>
				<Dump data={props} />
			</section>

			<section>
				<h1>
					Server-side number sum with <code>/api/v1/[handler]</code>
				</h1>
				<SumForm />
			</section> */}
			</PageContext.Provider>
		</>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const urlInfo = combineUrl(context)

	const url = `${urlInfo.path}`

	const dataFromContember = await content.query({
		// ...SiteFragment(urlInfo.locale),
		getHeader: [
			{
				by: {
					unique: One.One,
				},
			},
			HeaderFragment(),
		],
		listProgram: [{ orderBy: [{ order: OrderDirection.asc }] }, ProgramFragment()],
		getLinkable: [
			{ by: { url } },
			{
				id: true,
				url: true,
				homePage: [{}, HomePageFragment()],
				programPage: [{}, ProgramPageFragment()],
				program: [{}, ProgramFragment()],
				genericPage: [{}, GenericPageFragment()],
				newsPage: [{}, NewsPageFragment()],
				storiesPage: [{}, StoriesPageFragment()],
				news: [{}, NewsFragment()],
				story: [{}, StoriesFragment()],
			},
		],
		listStories: [
			{
				orderBy: [{ order: OrderDirection.asc }],
				filter: { isDraft: { notEq: true } },
			},
			StoriesFragment(),
		],
		listNews: [
			{
				filter: { isDraft: { notEq: true } },
				orderBy: [{ publishDate: OrderDirection.desc }],
			},
			NewsFragment(),
		],
		getFooter: [
			{
				by: {
					unique: One.One,
				},
			},
			FooterFragment(),
		],
	})

	if (
		!dataFromContember.getLinkable?.homePage &&
		!dataFromContember.getLinkable?.programPage &&
		!dataFromContember.getLinkable?.program &&
		!dataFromContember.getLinkable?.genericPage &&
		!dataFromContember.getLinkable?.newsPage &&
		!dataFromContember.getLinkable?.storiesPage &&
		!dataFromContember.getLinkable?.news &&
		!dataFromContember.getLinkable?.story
	) {
		throw new RespondWithNotFound('Blog post not found')
	}

	return {
		props: {
			url,
			urlInfo,
			dataFromContember,
			serverTime: new Date().toString(),
		},
	}
})

export const PageContext = createContext<null | CommonPageProps>(null)

export function usePageContext() {
	const context = useContext(PageContext)
	if (!context) {
		throw new Error('Missing PageContext')
	}
	return context
}
