import clsx from 'clsx'
import type { FooterResult } from '../data/content/FooterFragment'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './Footer.module.sass'
import { Link } from './Link'

export type FooterProps = {
	footer: FooterResult
}

export function Footer(props: FooterProps) {
	const socialGroup = props.footer.linksGroups.find((group) => group.specialIdentifier === 'social')

	return (
		<>
			<div className={s.Footer}>
				<Container size="wide">
					<div className={s.Columns}>
						{socialGroup && (
							<div className={s.Column}>
								<p className={s.Title}>{socialGroup.title}</p>
								{socialGroup.links.map((link) => (
									<Link link={link.link} key={link.id} className={s.Link} />
								))}
							</div>
						)}

						<div className={s.Column}>
							<script
								// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
								dangerouslySetInnerHTML={{
									__html: `
											(function (w,d,s,o,f,js,fjs) {
												w['ecm-widget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
												js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
												js.id = '2-2bb287d15897fe2f9d89c882af9a3a8b'; js.dataset.a = 'snf'; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
											}(window, document, 'script', 'ecmwidget', 'https://d70shl7vidtft.cloudfront.net/widget.js'));
										`,
								}}
							/>
							<div id="f-2-2bb287d15897fe2f9d89c882af9a3a8b" />
						</div>

						<div className={s.Column}>
							<a href={`tel:${props.footer.phone}`} className={clsx(s.Title, s.Phone)}>
								{props.footer.phone}
							</a>
							<a href={`mailto:${props.footer.email}`} className={s.Title}>
								{props.footer.email}
							</a>
							<p className={s.Text}>{props.footer.text}</p>
							{props.footer.link && <Button link={props.footer.link} />}
						</div>

						<div className={clsx(s.Logos, s.Column)}>
							{props.footer.logoFirst && (
								<div
									className={s.Logo}
									style={{ maxWidth: (props.footer.logoFirst.width ?? 0) / 2 }}>
									<ContemberImage image={props.footer.logoFirst} layout="intrinsic" />
								</div>
							)}
							{props.footer.logoSecond && (
								<div
									className={s.Logo}
									style={{ maxWidth: (props.footer.logoSecond.width ?? 0) / 2 }}>
									<ContemberImage image={props.footer.logoSecond} layout="intrinsic" />
								</div>
							)}
						</div>

						{props.footer.linksGroups
							.filter((group) => group.specialIdentifier !== 'social')
							.map((group) => (
								<div key={group.id} className={s.Column}>
									<p className={s.Title}>{group.title}</p>
									{group.links.map((link) => (
										<Link link={link.link} key={link.id} className={s.Link} />
									))}
								</div>
							))}
					</div>
					<p className={s.Copyright}>{props.footer.copyright}</p>
				</Container>
			</div>
		</>
	)
}
