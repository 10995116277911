import { NextSeo } from 'next-seo'
import type { FunctionComponent } from 'react'
import type { SeoResult } from '../data/content/SeoFragment'

export type SeoProps = {
	seo?: SeoResult
}

export const Seo: FunctionComponent<SeoProps> = ({ seo }) => {
	return (
		<NextSeo
			title={seo?.title}
			description={seo?.description}
			defaultTitle="Sociální nadační fond"
			openGraph={{
				title: seo?.ogTitle,
				description: seo?.ogDescription,
				images: seo?.ogImage?.url
					? [
							{
								url: seo?.ogImage?.url,
								width: seo?.ogImage?.width,
								height: seo?.ogImage?.height,
								alt: seo?.ogImage?.alt,
							},
					  ]
					: [],
			}}
		/>
	)
}
