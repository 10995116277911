import clsx from 'clsx'
import React, { useState } from 'react'
import type { HeaderResult } from '../data/content/HeaderFragment'
import { usePageLoadingState } from '../utils/usePageLoadingState'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './Header.module.sass'
import { Icon } from './Icon'
import { Link } from './Link'

export type HeaderProps = {
	header: HeaderResult
}

export function Header(props: HeaderProps) {
	const [open, setOpen] = useState(false)
	const [loading] = usePageLoadingState()

	React.useEffect(() => {
		setOpen(false)
	}, [loading])

	return (
		<Container size="wide">
			<div className={s.Header}>
				<div className={s.Logos}>
					<Link href="/">
						{props.header.logoFirst && (
							<div style={{ maxWidth: (props.header.logoFirst.width ?? 0) / 2 }}>
								<ContemberImage image={props.header.logoFirst} layout="intrinsic" />
							</div>
						)}
					</Link>
					{props.header.logoSecond && (
						<div style={{ maxWidth: (props.header.logoSecond.width ?? 0) / 2 }}>
							<ContemberImage image={props.header.logoSecond} layout="intrinsic" />
						</div>
					)}
				</div>

				<div className={s.Links}>
					{props.header.linkGroups?.items.map((item, i) => (
						<div key={item.id} className={s.MenuBox}>
							<Link
								link={item.link}
								className={clsx(s.Link, item.isHighlighted && s.LinkHighlighted)}
								activeClassName={s.isActiveLink}
							/>

							{item.group?.links?.items && item.group?.links?.items.length > 0 && (
								<div
									className={clsx(
										s.SubmenuWrapper,
										props.header.linkGroups?.items &&
											i === props.header.linkGroups?.items.length - 1 &&
											s.LastItem
									)}>
									<div className={s.Submenu}>
										{item.group?.links?.items.map((item) => (
											<div key={item.id} className={s.SubmenuLink}>
												<Link link={item.link} activeClassName={s.isActiveLink} />
											</div>
										))}
									</div>
								</div>
							)}
						</div>
					))}
				</div>

				<button className={s.IconHamburger} onClick={() => setOpen((old) => !old)}>
					<Icon name="hamburger" />
				</button>
				<div className={clsx(s.MobileHeader, open && s.isActive)}>
					<Container size="wide">
						<button
							className={clsx(s.IconHamburger, s.Close, open && s.isActive)}
							onClick={() => setOpen((old) => !old)}>
							<Icon name="cross" />
						</button>

						<div className={s.Logos}>
							<Link href="/">
								{props.header.logoFirst && (
									<div style={{ maxWidth: (props.header.logoFirst.width ?? 0) / 2 }}>
										<ContemberImage image={props.header.logoFirst} layout="intrinsic" />
									</div>
								)}
							</Link>
							{props.header.logoSecond && (
								<div style={{ maxWidth: (props.header.logoSecond.width ?? 0) / 2 }}>
									<ContemberImage image={props.header.logoSecond} layout="intrinsic" />
								</div>
							)}
						</div>

						<div className={clsx(s.Links, s.MobileLinks, open && s.isActive)}>
							{props.header.linkGroups?.items.map((item) => (
								<div key={item.id}>
									<Link link={item.link} className={s.Link} activeClassName={s.isActiveLink} />
									{item.group?.links?.items.map((item) => (
										<div key={item.id}>
											<Link
												link={item.link}
												className={s.SubmenuLink}
												activeClassName={s.isActiveLink}
											/>
										</div>
									))}
								</div>
							))}
						</div>
					</Container>
				</div>
			</div>
		</Container>
	)
}
